import styled from 'styled-components'
import { SmallText } from '~/styled/components/typography'

export const StyledTag = styled(SmallText)`
  font-family: Inter;
  padding: 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;

  &.transparent {
    background-color: transparent !important;
  }
`
