/** @jsx jsx */
// @ts-ignore
import { jsx, Container } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import { useSearchHandlers } from '~/components/header/use-search-handlers'
import { useOverlayContext } from '~/context/overlay-context'
import DesktopMainMenu from '~/components/menu-desktop/v2/item'
import { useMenu } from '~/context/menu-context'
import { useEffect } from 'react'

type Props = {
  isScrolling: boolean
  searchActive: boolean
}

const MenuDesktop = ({ isScrolling, searchActive }: Props) => {
  const { showSearch } = useSearchHandlers()
  const { toggleOverlay } = useOverlayContext()

  useEffect(() => {
    toggleOverlay(false)
  }, [])

  const { dynamicMenu } = useMenu()

  const handleMouseEnter = () => {
    toggleOverlay(true)
  }
  const handleMouseLeave = () => {
    if (!showSearch) {
      toggleOverlay(false)
    }
  }

  return (
    <Container>
      <div
        className={`menu desktop-menu ${searchActive ? 'prevent-submenus' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          justifyContent: 'stretch',
          zIndex: 1,
          mt: '-1px',
          '&.prevent-submenus': {
            a: {
              pointerEvents: 'none',
            },
          },
          'a,button': {
            color: 'inherit',
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          },
          '> ul > li > a,> ul > li > button': {
            display: 'block',
            height: isScrolling ? 40 : 60,
            lineHeight: isScrolling ? '40px' : '60px',
          },
          li: {
            position: 'relative',
            textAlign: 'left',
            textDecoration: 'none !important',
          },
          '> ul': {
            justifyContent: 'space-between',
          },
          ul: {
            variant: 'variants.list.none',
          },
          '> .menu-nav-item > button::first-child': {
            pl: 0,
          },
          'ul > ul': {
            position: 'fixed',
            left: 0,
            width: '100%',
            bg: 'main',
            borderColor: 'border',
            borderWidth: '1px',
            borderStyle: 'solid',
            'a,button': {
              display: 'block',
              px: space.md2,
              py: space.sm1,
              color: 'muted',
              textDecoration: 'none',
              ':hover': {
                color: 'text',
                fontWeight: '600',
              },
            },
          },
        }}
      >
        {dynamicMenu.map((menu, index) => (
          <DesktopMainMenu
            key={'menu-' + menu.id}
            menu={menu}
            index={index}
            searchActive={searchActive}
          />
        ))}
      </div>
    </Container>
  )
}

export default MenuDesktop
