import React from 'react'
import { StyledTag } from './styles'

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  bgColor?: string
  textColor?: string
  active?: boolean
  style?: React.CSSProperties
  disabled?: boolean
}

export const Tag: React.FC<TagProps> = ({
  children,
  bgColor = '#EDEDED',
  textColor = '#000',
  active = false,
  style,
  disabled = false,
  ...props
}) => {
  return (
    <StyledTag
      className={`tag${active ? ' active' : ''}${disabled ? ' disabled' : ''} ${
        bgColor === 'transparent' ? 'transparent' : ''
      }`}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        border: `1px solid transparent`,
        ...(active
          ? {
              borderColor: `${textColor}`,
              fontWeight: 600,
              marginLeft: 3,
            }
          : {
              borderColor: `transparent`,
              color: '#2b2b2b',
              fontWeight: 400,
            }),
        ...(disabled
          ? {
              opacity: 0.5,
            }
          : {}),
        ...style,
      }}
      {...props}
    >
      {children}
    </StyledTag>
  )
}
