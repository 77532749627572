/** @jsx jsx **/
import { Fragment, ReactNode, useMemo } from 'react'

import { jsx, Box, Text, Flex } from 'theme-ui'

import { space } from '~/gatsby-plugin-theme-ui'

import { Maybe, ProductItemComponentType, ProductVariantType } from '~/@types/models'

import { useCustomerContext } from '~/context/customer-context'

import CompareAtPrice from '~/components/compare-at-price'

import useMediaQuery from '~/hooks/use-media-query'

import {
  isInvalidTradeDiscountProduct,
  isValidTradeCustomerDiscount,
} from '~/utils/is-valid-trade-customer-discount'
import { formatMoney } from '~/utils/format'
import { Tag } from '~/componentsV2/tag'

interface IReturn {
  price: number
  priceFormatted: string
  priceWithoutTrade?: number
  isSale: boolean
  priceLabel: string
  compareAtPrice?: number
  finalPrice: number
  isTradeCustomer: boolean
  isTradeAccountLabelVisible: boolean
  isTradeProduct: boolean
  rangePrice?: ReactNode
  RangePrince?: ReactNode
}

type ProductVariantInfoType = {
  product: ProductItemComponentType
  productVariant?: Maybe<ProductVariantType>
  isStartingAt?: boolean
  isFabric?: boolean
}

const isRugs = ['CONTEMPORARY RUGS', 'TRADITIONAL RUGS', 'MOROCCAN RUGS']

const useProductVariantInfo = ({
  product,
  productVariant,
  isStartingAt = false,
  isFabric,
}: ProductVariantInfoType): IReturn => {
  const {
    customerType: { trade: isTradeCustomer },
  } = useCustomerContext()

  const allSale = useMemo(() => {
    const variantsWithoutSwatch = product?.variants?.filter(
      variant => !variant?.selectedOptions?.find(option => option.value === 'Swatch')
    )
    const sales = variantsWithoutSwatch?.filter(variant => variant?.compareAtPrice?.amount).length
    const variants = variantsWithoutSwatch.length

    // if (product.title === 'Sussex Sofa in Refined Saddle Wolf') {
    //   console.log({ sales, variants }, sales === variants)
    // }

    return sales === variants && sales
  }, [product])

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-device-width: ${breakpoints[2]})`,
  })

  // Cheapest
  const cheapestVariant = productVariant || product?.cheapestVariant

  const cheapestComparePriceUnformatted = Number(cheapestVariant?.compareAtPrice?.amount) || 0

  const compareAtPrice = Number(cheapestComparePriceUnformatted)
  const isSale = Boolean(compareAtPrice)

  const isTradeAccountLabelVisible = Boolean(
    !isSale && isValidTradeCustomerDiscount({ isTradeCustomer, productType: product?.productType })
  )

  const { cheapestVariantPrice, highestVariantPrice } = useMemo(
    () => new ProductPrice(product, productVariant, isFabric, isTradeCustomer),
    [product, productVariant, isFabric, isTradeCustomer]
  )

  const {
    compareAtPrice: cheapestComparePrice,
    price: cheapestPrice,
    tradePrice: cheapestTradePrice,
  } = cheapestVariantPrice.priceFormatted()
  const {
    compareAtPrice: highestComparePrice,
    price: highestPrice,
    tradePrice: highestTradePrice,
  } = highestVariantPrice.priceFormatted()

  const UppperTag = ({ isSale }: { isSale?: boolean }) =>
    cheapestVariantPrice.shoudShowTrade || isSale ? (
      <Tag
        bgColor="transparent"
        style={{
          padding: '0',
          minWidth: 'auto',
          marginLeft: '10px',
          fontSize: isMobile ? '8px' : 'inherit',
        }}
      >
        {allSale ? 'SALE' : 'TRADE PRICE'}
      </Tag>
    ) : null

  const DownTag = ({ text }: { text?: string }) =>
    text ? (
      <Tag bgColor="transparent" style={{ padding: '0', minWidth: 'auto', marginLeft: '10px' }}>
        {text}
      </Tag>
    ) : null

  const tradeLabelCustom = (isStartingAt: boolean) =>
    isTradeAccountLabelVisible ? (
      <Flex
        as={Text}
        sx={{
          flexDirection: `column`,
        }}
      >
        <CompareAtPrice
          price={cheapestTradePrice}
          sxProps={{
            fontSize: ['inherit', null, 'inherit'],
            mb: [16],
            color: 'muted',
            mr: space.xxs,
          }}
        />
        <Text
          as="span"
          sx={{
            fontSize: ['18px'],
            lineHeight: [1.5],
            textTransform: 'uppercase',
            color: '#595858',
          }}
        >
          Trade Price: {isStartingAt && 'Starting at'}{' '}
          {isFabric
            ? `$${cheapestVariantPrice.tradePrice}`
            : formatMoney(cheapestVariantPrice.tradePrice)}
          {isFabric ? '/YARD' : ''}
        </Text>
      </Flex>
    ) : (
      <Box as="span">
        {isStartingAt && 'Starting at'} {cheapestTradePrice}
      </Box>
    )

  const priceLabel = tradeLabelCustom(isStartingAt)
  const PriceText = ({ children, strikePrice }: { strikePrice?: boolean; children: ReactNode }) => (
    <Text
      color="txt"
      data-comp="price-text"
      sx={{
        fontSize: ['10px', null, 'inherit'],
        textDecoration: strikePrice ? 'line-through' : 'none',
        color: strikePrice ? 'grey' : 'txt',
        lineHeight: 1.5,
      }}
    >
      {children}
    </Text>
  )

  const PriceBox = ({
    highPrice,
    cheapPrice,
    children,
    strikePrice,
  }: {
    highPrice?: string
    cheapPrice: string
    children?: ReactNode
    strikePrice?: boolean
  }) => {
    return (
      <Flex data-comp="price-box" sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <PriceText strikePrice={strikePrice}>{cheapPrice}</PriceText>
        {Number(highPrice?.replace(/[^0-9.]/g, '')) > 0 && (
          <PriceText strikePrice={strikePrice}>
            <Fragment>
              {' — '}
              {highPrice}
            </Fragment>
          </PriceText>
        )}
        {children}
      </Flex>
    )
  }

  const createRangePrice = () => {
    return (
      <Flex
        data-comp="createRangePrice-price"
        className="range-price"
        sx={{
          flexDirection: 'column',
        }}
      >
        {cheapestVariantPrice.price !== highestVariantPrice.price ? (
          <Fragment>
            <PriceBox
              cheapPrice={
                cheapestVariantPrice.shoudShowTrade && !cheapestVariantPrice.isSale
                  ? cheapestTradePrice
                  : cheapestPrice
              }
              highPrice={
                highestVariantPrice.shoudShowTrade && !cheapestVariantPrice.isSale
                  ? highestTradePrice
                  : highestPrice
              }
            >
              <UppperTag isSale={cheapestVariantPrice.isSale} />
            </PriceBox>
            {cheapestVariantPrice.isSale || cheapestVariantPrice.shoudShowTrade ? (
              <PriceBox
                cheapPrice={cheapestVariantPrice.isSale ? cheapestComparePrice : cheapestPrice}
                highPrice={highestVariantPrice.isSale ? highestComparePrice : highestPrice}
                strikePrice
              >
                {/* {!highestVariantPrice.compareAtPrice && (
                  <DownTag text="Some variants are on sale" />
                )} */}
              </PriceBox>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            {cheapestComparePrice !== '$0' ? (
              <Fragment>
                {cheapestVariantPrice.isSale ? (
                  <Fragment>
                    <PriceText>
                      {cheapestPrice}
                      <UppperTag isSale={cheapestVariantPrice.isSale} />
                    </PriceText>
                    <PriceText strikePrice>{cheapestComparePrice}</PriceText>
                  </Fragment>
                ) : (
                  <PriceText>{cheapestPrice}</PriceText>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <PriceBox
                  cheapPrice={
                    cheapestVariantPrice.shoudShowTrade ? cheapestTradePrice : cheapestPrice
                  }
                >
                  <UppperTag />
                </PriceBox>
                {cheapestVariantPrice.shoudShowTrade && (
                  <PriceBox cheapPrice={cheapestPrice} strikePrice />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Flex>
    )
  }

  return {
    price: cheapestVariantPrice.price,
    rangePrice: createRangePrice(),
    priceFormatted: cheapestPrice,
    priceWithoutTrade: cheapestVariantPrice.price,
    isSale,
    priceLabel,
    compareAtPrice: cheapestVariantPrice.compareAtPrice,
    compareAtPriceFormatted: cheapestComparePrice,
    finalPrice: cheapestVariantPrice.shoudShowTrade ? cheapestTradePrice : cheapestTradePrice,
    isTradeCustomer,
    isTradeAccountLabelVisible,
    isTradeProduct: isInvalidTradeDiscountProduct(product?.productType),
  }
}

export default useProductVariantInfo

class Prices {
  private product: Pick<ProductVariantType, 'price' | 'compareAtPrice' | 'discountAllocations'>
  private productType: string
  private isFabric?: boolean
  private isTradeAccount?: boolean

  constructor(
    product: ProductVariantType,
    productType: string,
    isFabric?: boolean,
    isTradeAccount?: boolean
  ) {
    this.product = product
    this.isFabric = isFabric
    this.isTradeAccount = isTradeAccount
    this.productType = productType
  }

  private isRugs = ['CONTEMPORARY RUGS', 'TRADITIONAL RUGS', 'MOROCCAN RUGS']

  get price() {
    return Number(this.product?.price?.amount) || 0
  }

  get compareAtPrice() {
    return Number(this.product?.compareAtPrice?.amount) || 0
  }

  get percentOff() {
    const discountPercentage = this.product?.discountAllocations
      ?.filter(o => {
        return 'percentage' in o.discountApplication.value
      })
      ?.reduce((acc, o) => {
        if ('percentage' in o.discountApplication.value) {
          acc += o.discountApplication.value.percentage
        }
        return acc
      }, 0)

    return discountPercentage || 0
  }

  get discountPrice() {
    return this.price - (this.price * this.percentOff) / 100
  }

  get tradePrice() {
    return this.calculateTradeDiscount(Number(this.product?.price?.amount) || 0) || 0
  }

  get isSale() {
    return this.compareAtPrice > 0 && this.price !== this.compareAtPrice
  }

  get shoudShowTrade() {
    return this.price !== this.tradePrice
  }

  calculateTradeDiscount = (price: number): number => {
    if (!this.isTradeAccount || this.compareAtPrice) return price
    const isRugProduct = this.isRugs.includes(this?.productType)

    return price * (isRugProduct ? 0.75 : 0.8)
  }

  parseValue(price: number, long = false) {
    if (this.isFabric) {
      return formatMoney(price).replace('.00', '/YARD')
    }
    return formatMoney(price, 'USD', {
      minimumFractionDigits: !long ? 0 : 2,
      maximumFractionDigits: !long ? 0 : 2,
    })
  }

  priceFormatted(long?: boolean) {
    return {
      price: this.parseValue(this.price, long),
      compareAtPrice: this.parseValue(this.compareAtPrice, long),
      tradePrice: this.parseValue(this.tradePrice, long),
      discountPrice: this.parseValue(this.discountPrice, long),
    }
  }
}

class ProductPrice {
  private product: ProductItemComponentType
  private productVariant: Maybe<ProductVariantType>
  private isFabric?: boolean
  private isTradeAccount?: boolean

  constructor(
    product: ProductItemComponentType,
    productVariant: Maybe<ProductVariantType>,
    isFabric?: boolean,
    isTradeAccount?: boolean
  ) {
    this.product = product
    this.productVariant = productVariant
    this.isFabric = isFabric
    this.isTradeAccount = isTradeAccount
  }

  get cheapestVariantPrice() {
    const variant = this.productVariant || this.product?.cheapestVariant || this.product
    return new Prices(variant, this.product?.productType, this.isFabric, this.isTradeAccount)
  }

  get highestVariantPrice() {
    const variant = this.productVariant || this.product?.highestVariant || this.product
    return new Prices(variant, this.product?.productType, this.isFabric, this.isTradeAccount)
  }
}

export { ProductPrice, Prices }
