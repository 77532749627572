/** @jsx jsx **/
import { FC, useEffect, useState } from 'react'
import { Box, jsx, Heading } from 'theme-ui'
import { useCartContext } from '~/context/cart-context'

type Props = {
  totalItems?: number
}

const CartOverlayHeader: FC<Props> = () => {
  const { totalQuantity, isFetching } = useCartContext()
  const [currentTotalQuantity, setCurrentTotalQuantity] = useState(0)

  useEffect(() => {
    if (!isFetching) {
      setCurrentTotalQuantity(totalQuantity)
    }
  }, [isFetching])
  return (
    <Box
      as="header"
      sx={{
        pb: ['15px', null, '0'],
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Heading
          as="h3"
          variant="title4"
          sx={{
            py: ['8px', '26px'],
            pl: ['0'],
            textTransform: 'uppercase',
            fontSize: ['22px!important'],
            borderBottom: '1px solid #E6E6E6',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="cart-header-title"
        >
          My Cart{' '}
          <span
            sx={{
              fontSize: ['12px', '15px'],
              fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
            }}
          >
            {currentTotalQuantity} {currentTotalQuantity === 1 ? 'item in cart' : 'items in cart'}
          </span>
        </Heading>
      </Box>
    </Box>
  )
}

export default CartOverlayHeader
