/** @jsx jsx */
import React, { FC, useMemo } from 'react'
import { Link } from 'gatsby'
// @ts-ignore
import { jsx, Box, Flex } from 'theme-ui'
import urls from '~/common/urls'
import { useCustomerContext } from '~/context/customer-context'
import Search from '~/components/search'
import { space } from '~/gatsby-plugin-theme-ui'
import { useSearchHandlers } from '~/components/header/use-search-handlers'
import MobileMainMenu from '~/components/menu-mobile/v2/item'
import { useMenu } from '~/context/menu-context'
import MobileContent from '../header/mobileContent'

type Props = {
  isScrolling: boolean
  children?: React.ReactNode
}

const MenuMobile: FC<Props> = ({ isScrolling, children }) => {
  const { isLoggedIn, customer } = useCustomerContext()
  const { showSearch, toggleSearch } = useSearchHandlers()
  const { dynamicMenu } = useMenu()

  const mappedMenu = dynamicMenu.map(item => <MobileMainMenu key={'menu' + item.id} item={item} />)

  return (
    <Box
      sx={{
        position: 'absolute',
        bg: 'main',
        // height: 'calc(100vh - 250px)',
        height: '100vh',
        overflowY: 'auto',
        width: '100%',
        top: 0,
        pb: 200,
        '.mobile-content': {
          svg: {
            fill: '#000',
          },
          button: {
            color: 'black',
          },
        },
      }}
    >
      <Box as="nav" className="menu">
        <MobileContent headerVariant="dark">{children}</MobileContent>
        <Search toggleSearch={toggleSearch} />
        {!showSearch && (
          <Box
            sx={{
              '.menu-link': {
                color: 'zambezi',
                textDecoration: 'none',
                fontSize: ['12px', '14px', '16px'],
                border: '1px solid',
                borderColor: 'border',
                display: 'block',
                px: space.md2,
                py: 20,
                background: 'transparent',
                width: '100%',
                textAlign: 'left',
                mt: -1,
                ':hover': {
                  textDecoration: 'underline',
                  color: 'text',
                },
                ul: {
                  mergeLeft: 20,
                },
              },
              '.hasSubmenu .menu-link:hover': {
                textDecoration: 'none',
              },
              li: {
                position: 'relative',
              },
              ul: {
                variant: 'variants.list.none',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              },
              'ul ul .menu-link': {
                color: 'muted',
                ':hover': {
                  textDecoration: 'underline',
                  color: 'text',
                },
              },
              'ul ul': {
                display: 'none',
              },
              'ul ul > li > .menu-link': {
                pl: 40,
              },
              'ul ul ul > li > .menu-link': {
                pl: 60,
              },
              'ul ul ul ul > li > .menu-link': {
                pl: 80,
              },
            }}
          >
            <ul>
              <li>
                <Link
                  sx={{ textTransform: 'uppercase', fontWeight: 600 }}
                  to={isLoggedIn ? urls.account.accountPersonalData : urls.account.signIn}
                  data-cy="account-link"
                  className="menu-link"
                >
                  {isLoggedIn && customer?.email ? `${customer?.email}` : 'Account'}
                </Link>
              </li>
              <li>
                <Link
                  sx={{ textTransform: 'uppercase', fontWeight: 600 }}
                  to="/collections/new-arrivals"
                  className="menu-link"
                >
                  New Launches
                </Link>
              </li>
            </ul>
            <ul>{mappedMenu}</ul>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default MenuMobile
