/** @jsx jsx **/
import { Box, jsx } from 'theme-ui'
import { CartItem } from '~/components/cart-item'
import { useCartContext } from '~/context/cart-context'
import { useRemovedItem } from '~/components/cart-item/removedItem'

import { DashboardSflCarousel } from '~/components/account-layout/components/saveForLaterCarousel'
import { LoadingText } from '~/componentsV2/loadingText'
import { H2 } from '~/styled/components/typography'
const CartOverlayBody = () => {
  const { items, isFetching } = useCartContext()

  const { RemovedItem, onRemove } = useRemovedItem()

  if (isFetching) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingText />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        flex: 'auto',
        minHeight: '1%',
        overflow: 'auto',
        mr: [0],
        pt: [null, '20px'],
        pb: [null, '0'],
        overflowX: 'hidden',
      }}
      className="cart-overlay-body"
    >
      <RemovedItem />
      {items?.length ? (
        items.map(item => (
          <CartItem
            variant="secondary"
            data-cy="cart-item"
            item={item}
            key={`${item.id}${item.quantity}`}
            onRemove={onRemove}
            source="cart-flyout"
          />
        ))
      ) : (
        <Box
          sx={{
            p: [40, null, 60],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <H2>Your cart is empty.</H2>
        </Box>
      )}

      <Box sx={{ maxWidth: '80%', margin: '0 auto' }}>
        <DashboardSflCarousel cols={2} />
      </Box>
    </Box>
  )
}

export default CartOverlayBody
