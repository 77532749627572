/** @jsx jsx **/
import { Box, Flex, Grid, jsx, Text } from 'theme-ui'
import { useCallback } from 'react'
import { navigate, useLocation } from '@reach/router'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import { useCartContext } from '~/context/cart-context'
import { formatMoney } from '~/utils/format'
import { lineItemsSummary } from '~/utils/price'
import { useCustomerContext } from '~/context/customer-context'
import { trackInitiateCheckout } from '~/utils/marketing/track'
import useCheckoutLink from '~/hooks/use-checkout-link'

const ButtonProceedToCheckout = () => {
  const { pathname } = useLocation()
  const checkoutLink = useCheckoutLink()
  const { setOverlayOpen, items } = useCartContext()

  const handleCloseOverlay = useCallback(() => {
    setOverlayOpen(false)
  }, [setOverlayOpen])

  const onClickCheckout = useCallback(() => {
    trackInitiateCheckout({
      contentCategory: pathname,
      contents: items.map(item => ({
        id: item.variant.shopifyId,
        quantity: item.quantity,
        item_price: item.variant.price,
      })),
    })

    if (checkoutLink) {
      navigate(checkoutLink)
    }
    handleCloseOverlay()
  }, [handleCloseOverlay, pathname, items, checkoutLink])

  return (
    <Button
      label="PROCEED TO CHECKOUT"
      as="button"
      variant="buttons.secondary"
      onClick={onClickCheckout}
      sxProps={{
        fontSize: ['12px', '16px'],
        fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
      }}
    />
  )
}

const CartOverlayFooter = () => {
  const { checkout, items, setOverlayOpen } = useCartContext()
  const { customerType } = useCustomerContext()

  const handleCloseOverlay = useCallback(() => {
    setOverlayOpen(false)
  }, [setOverlayOpen])

  const totalPrice = lineItemsSummary(customerType.trade, items)

  return (
    <Box
      as="footer"
      sx={{
        pt: ['0', null, '10px'],
        pb: ['40px'],
        borderTop: '1px solid #E6E6E6',
      }}
      className="cart-overlay-footer"
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          mb: ['12px', null, 'md2'],
          color: 'text',
          pt: ['18px'],
        }}
      >
        <Text variant="regular">
          <span
            sx={{
              fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
              textTransform: 'uppercase',
              fontSize: ['12px', '15px'],
            }}
          >
            Estimated Totals
          </span>
        </Text>
        <Text
          variant="regular" // marketing tools class
          className="cart-total"
          data-cy="cart-total"
          sx={{
            fontSize: ['12px', '15px!important'],
            fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
          }}
        >
          {checkout ? formatMoney(totalPrice) : formatMoney('0')}
        </Text>
      </Flex>
      <Grid gap="12px">
        <Button
          label="View Cart"
          as="link"
          to={urls.purchase.cart}
          onClick={handleCloseOverlay}
          sxProps={{
            fontSize: ['12px', '16px'],
            fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
          }}
        />
        {checkout && <ButtonProceedToCheckout />}
      </Grid>
    </Box>
  )
}

export default CartOverlayFooter
