/** @jsx jsx */
import { jsx, Box, Text, Grid, Card, Image } from 'theme-ui'
import { space } from '~/gatsby-plugin-theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import CustomMenuButton from '~/components/CustomMenuButton/customMenuButton'
import { AddToCartButton } from '~/components/add-to-card-button'
import { Link } from 'gatsby'
import AspectRatio from '~/componentsV2/aspectRatio'
import useProductVariantInfo from '~/hooks/use-product-variant-info'
import { FC, useMemo } from 'react'
import { useDashboard } from '../context/dashboardContext'
import { useAlgoliaTracking } from '~/hooks/use-algolia-tracking'

type Props = {
  list: any
  listName: string
  id?: string
  sfl?: boolean
}

function getSubstringBeforeQuestionMark(url?: string) {
  if (!!url) {
    const questionMarkIndex = url.indexOf('?')
    return questionMarkIndex !== -1 ? url.slice(0, questionMarkIndex) : url
  }
}

function getSubstringBeforeOthenMark(title?: string) {
  if (!!title) {
    const otherMarkIndex = title.indexOf(' –')
    return otherMarkIndex !== -1 ? title.slice(0, otherMarkIndex) : title
  }
}

const extractHandle = (url?: string) => {
  if (url) {
    const _url = url.split('products/')[1].split('?')[0].split('/')
    return _url[0]
  }
}

export const DashboardProductCard: FC<Props> = ({ list, listName, id, sfl }) => {
  const productHandle = extractHandle(list.du)
  const { products, removeFromSfl } = useDashboard()

  const product = products.find(product => product.handle === productHandle)
  const productVariant = product?.variants.find(
    variant => variant.shopifyId2 === list.epi.toString()
  )

  const { rangePrice } = useProductVariantInfo({
    product,
    productVariant,
  })

  if (!product) return null

  const selectedVariant = productVariant
  if (!!selectedVariant) {
    selectedVariant.shopifyId = selectedVariant?.id
    selectedVariant.shopifyId2 = list.epi.toString()
  }
  const productVarianUrl = list.du?.slice(34)
  const productTitle = getSubstringBeforeOthenMark(list.dt)

  const removeFromSflHandler = () => {
    removeFromSfl({ productVariantId: list.epi, shopifyId: list.empi, product })
  }

  return (
    <Card
      className="product-card"
      sx={{
        justifySelf: 'center',
        position: 'relative',
        minHeight: '25vh',
        width: '100%',
        display: 'grid',
      }}
    >
      <AspectRatio ratio={1}>
        <Link
          style={{
            textDecoration: 'none',
          }}
          to={`${productVarianUrl}`}
        >
          <Image
            src={list.iu}
            sx={{
              width: '100%',
              height: '100%',
              alignContent: 'center',
              background: '#f8f8f8',
              objectFit: list.dt.toLowerCase().includes('rug') ? 'cover' : 'contain',
            }}
          />
        </Link>
      </AspectRatio>
      <CustomMenuButton
        type={true}
        listName={listName}
        productName={list.dt}
        product={list.empi}
        productVariant={list.epi}
        productUrl={list.du}
        listId={id}
        sfl={sfl}
      />
      <Grid
        className="product-card__text"
        columns={[1]}
        gap={space.gridGap3}
        sx={{
          flexDirection: 'column',
          boxSizing: 'border-box',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        <Link
          style={{
            textDecoration: 'none',
          }}
          to={`${productVarianUrl}`}
        >
          <Box sx={{ maxHeight: '62px' }}>
            <Text
              sx={{
                fontFamily: 'Cormorant Garamond',
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '19px',
                letterSpacing: '0em',
                color: '#2b2b2b',
                textTransform: 'uppercase',
              }}
            >
              {list.dt}
            </Text>
          </Box>
        </Link>
        <Box>{rangePrice}</Box>
        <Box>
          <AddToCartButton
            title={productTitle}
            variant={selectedVariant}
            initialQuantity={1}
            sxProps={{
              width: '100%',
              height: '42px',
              minWidth: 'auto',
            }}
            label="Add to Cart"
            callback={removeFromSflHandler}
          />
        </Box>
      </Grid>
    </Card>
  )
}
