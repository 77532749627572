/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import CartOverlayWrapper from '~/components/cart-overlay/cart-overlay-wrapper'
import CartOverlayBody from '~/components/cart-overlay/cart-overlay-body'
import CartOverlayHeader from '~/components/cart-overlay/cart-overlay-header'
import CartOverlayFooter from '~/components/cart-overlay/cart-ovelay-footer'

type Props = {}

const CartOverlayContent: FC<Props> = () => {
  return (
    <CartOverlayWrapper>
      <CartOverlayHeader />
      <CartOverlayBody />
      <CartOverlayFooter />
    </CartOverlayWrapper>
  )
}

export default CartOverlayContent
